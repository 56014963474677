// forms
form,
fieldset {
    margin: 0;
    padding: 0;
    border-style: none;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
// input[type='date'],
textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    box-sizing: border-box;
    border: 1px solid #c2c1c1;
    // border: none;
    padding: $form-element-padding;
    background: #ececec;
    outline: none;
    // &:hover {
    // }
    // &:focus {
    //     border-color: $form-element-focus-border-color;
    // }
    @include placeholder {
        color: $placeholder-color;
    }
}

form#paymentForm {
    input[type='text'] {
        background: #fff;
        @include placeholder {
            color: $black;
        }
    }
}

select {
    -webkit-border-radius: 0;
}

textarea {
    resize: vertical;
    vertical-align: top;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
    font-family: $heading-font-family;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    outline: none;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    color: #fff;
    padding: 6px 0;
    font-size: 1.2rem;
}
input[type='file'] {
    min-height: 1px;
    min-width: 1px;
}

input[disabled] {
    color: #aaa;
}

.hidden {
    display: none !important;
}
.form-section {
    
    margin: 0 0 1rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid $gray;
    &.last {
        border-bottom: 0;
    }
}


.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -15px;
    border: 1px solid #fff;
    @include media ('<=phone') {
        flex-wrap: wrap;
    }
    &.inline {
        margin: 0;
        .form-input{
            width: auto;
            &:not(last-child) {
                margin-right: 6px;
            }
            
        }
    }
}


.form-row label {
    display: flex;
    padding-bottom: .8rem;
    &.required:after {
        content: ' *';
        color: $poppy;
    }
}

.form-row .form-label {
    font-weight: bold
}

.form-row .form-label.form-required:after {
    content: "*";
    margin-left: 5px;
    color:$poppy;
}

.form-row .form-input {
    width: 100%;
    display: block;
    box-sizing: border-box;
}

.form-row .form-input[type=checkbox],
.form-row .form-input[type=radio] {
    width: auto;
    width:1.5rem;
    height:1.5rem;
    display: inline;
    margin-right: 5px
}

.form-row .form-input[type=checkbox] {
    width: 2.5rem;
    height: 2.5rem;
}

.form-row .form-input.StripeElement {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
    border: 1px solid;
    padding-top: 5px;
    height: 26px
}

.form-row .form-input-only-label {
    font-weight: normal
}

.form-row .form-input-only-label>.form-input {
    display: inline-block;
    width: auto;
    margin-right: 5px
}

.form-row .form-errors, ul.parsley-errors-list {
    list-style: none;
    padding: 0;
    margin: 5px 0 0
}

.form-row .form-errors>li, ul.parsley-errors-list>li {
    color: $poppy
}

.form-row .form-instructions {
    font-size: 1.1rem;
    line-height: 1.3;
    color: $black;
    margin-bottom: 1em;
}

.form-column {
    width: 100%;
    padding: 1rem;
    &.header {
        padding: 1rem 1rem 0;
    }
    &.half {
        width: 50%;
        @include media('<=phone') {
            width: 100%;
        }
    }
    &.w-half, &.w-1\/2 {
        width: 50%;
        @include media('<=phone') {
            width: 100%;
        }
    }
    &.w-1\/3 {
        width: 33%;
        @include media('<=phone') {
            width: 100%;
        }
    }
    &.w-quarter, &.w-1\/4 {
        width: 25%;
        @include media('<=phone') {
            width: 100%;
        }
    }
    &.w-sixth, &.w-1\/6 {
        width: 16.5%;
        @include media('<=phone') {
            width: 100%;
        }
    }
    @include media('<=phone') {
        flex-direction: column;
    }
    h3, h4 {
        margin-bottom: 0;
    }

    table {
        width: 100%;
        td input {
            width: 90%;
        }
        th {
            text-align: left;
        }
        td, th {
            padding: 0 0 10px 0;
        }
    }
}

.form-form-has-errors, .freeform-form-has-errors, .error {
    color: $poppy;
}

ul.errors {
    list-style: none;
    margin: 0;
    padding: .5rem;
    font-weight: 600;
    background: $poppy;
    color: #fff;
}

.flatpickr-time {
    background: #ccc;
}

.flatpickr-time input {
    font-size: 1.5rem;
    background: #ccc;
}

/**FORMIE STYLING**/
form.fui-form {
    --fui-row-gutter: 2rem;
    --fui-label-font-size: 1.1rem;
    --fui-gray-400: #555;
    .fui-instructions p {
        font-size: 1rem;
        line-height: 1.4;
    }
    .fui-checkbox-label, .fui-radio-label {
        color: #222;
    }
}

