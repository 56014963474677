// Typography
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
    font-family: $heading-font-family;
    font-weight: 600;
    margin: 0 0 0.5em;
    color: $headings-color;
}

h1,
.h1 {
    font-size: $h1-font-size;
    @include media('<=desktop') {
        font-size: 2.2rem;
    }
    @include media('<=phone') {
        font-size: 2rem;
    }
}

h2,
.h2 {
    font-size: $h2-font-size;
    @include media('<=phone') {
        font-size: 1.6rem;
    }
}

h3,
.h3 {
    font-size: $h3-font-size;
    @include media('<=phone') {
        font-size: 1.4rem;
    }
}

h4,
.h4 {
    font-size: $h4-font-size;
}

h5,
.h5 {
    font-size: $h5-font-size;
}

h6,
.h6 {
    font-size: $h6-font-size;
}

p {
    margin: 0 0 1em;
    font-size: 1.1rem;
    line-height: 1.3;
    @media screen and (max-width: 640px){
        font-size: 1rem;
    }
}

li {
    font-size: 1.1rem;
    line-height: 1.3;
    @media screen and (max-width: 640px) {
        font-size: 1rem;
    }
}

a {
    // color: $base-link-color;
    color: $bubblegum;
    text-decoration: none;
    transition: color .3s ease-out, background .3s ease-out, opacity .3s ease-out;
    &:hover,
    &:focus {
        // text-decoration: none;
    }
}

.warning {
    color: $poppy;
}

//Default colors
.bg-monstera {background-color: $monstera; color: $white;}
.bg-denim {background-color: $denim; color: $white;}
.bg-sand {background-color: $sand; color: $black;}
.bg-sunflower {background-color: $sunflower; color: $black;}
.bg-flamingo{background-color: $flamingo; color: $poppy;}
.bg-powder{background-color: $powder; color: $black;}
.bg-white{background-color: #fff; color: $black;}
.bg-poppy{background-color: $poppy; color: $black;}
.bg-black{background-color: rgb(37, 34, 34) !important; color: $white;}
.bg-blue{background-color: $blue; color: #fff;}
.bg-orange{background-color: $orange; color: $black;}
.bg-brown{background-color: $brown; color: $white;}
.bg-light-blue{background-color: $light-blue; color: $black;}
.bg-teal{background-color: $teal; color: $black;}
.bg-panther{background-color: $panther; color: $black;}
.bg-pencil{background-color: $pencil; color: $black;}
.bg-earl{background-color: $earl; color: $black;}
.bg-smurfette{background-color: $smurfette; color: $black;}
.bg-grape{background-color: $grape; color: #000;}
.bg-franklin{background-color: $franklin; color: $pencil;}
.bg-paper{background-color: $paper; color: $black;}
.bg-crazy-pink{background-color: $crazy-pink; color: $black;}
.bg-lemon{background-color: $lemon; color: #000;}
.bg-tiffany{background-color: $tiffany; color: #000;}
.bg-street{background-color: $street; color: #000;}
.bg-lipstick{background-color: $lipstick; color: #fff;}
.bg-pirate-boot{background-color: $pirate-boot; color: #fff;}
.bg-grumpy-bear{background-color: $grumpy-bear; color: $pirate-boot;}
.bg-army-man{background-color: $army-man; color: #fff;}
.bg-bubblegum{background-color: $bubblegum; color: #fff;}
.bg-big-bird{background-color: $big-bird; color: $pirate-boot;}
.bg-almost-white{background-color: $almost-white; color: $pirate-boot;}

//Default link colors
.bg-sand, .bg-powder, .bg-white, .bg-poppy, .bg-flamingo, .bg-light-blue, .bg-teal, .bg-pencil, .bg-panther, .bg-earl, .bg-smurfette, .bg-paper, .bg-crazy-pink {
  a {color: $black;}
}
.bg-sunflower, .bg-powder {
  a {color: $monstera;}
}
.bg-monstera, .bg-denim, .bg-black, .bg-blue, .bg-orange, .bg-brown {
  a {color: $white;}
}

.bg-grape, .bg-franklin, .bg-lemon, .bg-tiffany, .bg-street, .bg-grumpy-bear, .bg-bubblegum, .bg-big-bird, .bg-almost-white {
  a {color: #000;}
}

.text-monstera, .text-monstera a {color: $monstera !important;}
.text-denim, .text-denim a {color: $denim !important;}
.text-sand, .text-sand a {color: $sand !important;}
.text-sunflower, .text-sunflower a {color: $sunflower !important;}
.text-flamingo, .text-flamingo a {color: $flamingo !important;}
.text-powder, .text-flamingo a {color: $powder !important;}
.text-poppy, .text-poppy a {color: $poppy !important;}
.text-white, .text-white a {color: #fff !important;}
.text-black, .text-black a {color: #000 !important;}
.text-blue, .text-blue a {color: $blue !important;}
.text-orange, .text-orange a {color: $orange !important;}
.text-brown, .text-brown a {color: $brown !important;}
.text-light-blue, .text-light-blue a {color: $light-blue !important;}
.text-teal, .text-teal a {color: $teal !important;}
.text-panther, .text-panther a {color: $panther !important;}
.text-pencil, .text-pencil a {color: $pencil !important;}
.text-earl, .text-earl a {color: $earl !important;}
.text-smurfette, .text-smurfette a {color: $smurfette !important;}
.text-grape, .text-grape a {color: $grape !important;}
.text-franklin, .text-franklin a {color: $franklin !important;}
.text-paper, .text-paper a {color: $paper !important;}
.text-crazy-pink, .text-crazy-pink a {color: $crazy-pink !important;}
.text-lemon, .text-lemon a {color: $lemon !important;}
.text-tiffany, .text-tiffany a {color: $tiffany !important;}
.text-street, .text-street a {color: $street !important;}
.text-lipstick, .text-lipstick a {color: $lipstick !important;}
.text-pirate-boot, .text-pirate-boot a {color: $pirate-boot !important;}
.text-grumpy-bear, .text-grumpy-bear a {color: $grumpy-bear !important;}
.text-army-man, .text-army-man a {color: $army-man !important;}
.text-bubblegum, .text-bubblegum a {color: $bubblegum !important;}
.text-big-bird, .text-big-bird a {color: $big-bird !important;}
.text-almost-white, .text-almost-white a {color: $almost-white !important;}