// list reset
%listreset {
	margin: 0;
	padding: 0;
	list-style: none;
}

// clearfix
%clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.clearfix {
	@extend %clearfix;
}


// transition
%transition {
	transition: all 0.3s ease-in-out;
}

// justify nav
%justify {
	text-align: justify;
	font-size: 1px;
	line-height: 0px;
	> * {
		display: inline-block;
		vertical-align: top;
		text-align: left;
		font-size: $font-size-base;
		line-height: $line-height-base;
	}
	&:after {
		content: '';
		width: 100%;
		display: inline-block;
		vertical-align: top;
	}
}



/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
	white-space: nowrap; /* 1 */
	text-overflow: ellipsis; /* 2 */
	overflow: hidden;
}

.flex {
    display: flex;
    &.flex-center {
        justify-content: center;
        align-items: center;
    }
    &.col {
		flex-direction: column;
	}
	&.justify-between {
		justify-content: space-between;
	}
	&.justify-around {
		justify-content: space-around;
	}
	&.justify-end {
		justify-content: flex-end;
	}
	&.items-center {
		align-items: center;
	}
}

.flex-wrap {
	flex-wrap: wrap;
}

.grid {
	display: flex;
}

.w-full {
	width: 100%;
}
.w-half, .w-1\/2 {
	width: 50%;
	@include media('<=phone') {
		width: 100%;
	}
}
.w-1\/3 {
	width: 33%;
	@include media('<=phone') {
		width: 100%;
	}
}
.w-quarter, .w-1\/4 {
	width: 25%;
	@include media('<=phone') {
		width: 100%;
	}
}
.w-sixth, .w-1\/6 {
	width: 16.5%;
	@include media('<=phone') {
		width: 100%;
	}
}
.list-reset {
	list-style: none;
	padding: 0;
}

.text-right {
	text-align: right;
}
.text-center {
	text-align: center;
}
.sm-text-center {
	@include media('<=phone') {
		text-align: center;
	}
}

.muted {
	color: $gray;
}


