/* custom select styles */

.jcf-select {
    display: inline-block;
    vertical-align: top;
    position: relative;
    border: none;
    color: #fff;
    background: #3e4046;
    min-width: 180px;
    border-radius: 6px;
    @include media ('<=phone') {
        background: #3aa592;
        min-width: 125px;
    }
}

.jcf-select select {
    z-index: 1;
    left: 0;
    top: 0;
}

.jcf-select .jcf-select-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    display: block;
    font-size: 17px;
    line-height: 26px;
    // padding: 6px 12px 6px 0;
    padding: 6px 40px 6px 40px;
    text-align: center;
    letter-spacing: 1px;
    position: relative;
    @include media ('<=phone') {
        padding: 7px 0 4px 15px;
        line-height: inherit;
        font-size: 12px;
    }
    &::before,
    &::after {
        content: '';
        z-index: 1;
        position: absolute;
    }
    &::before {
        top: 8px;
        left: 12px;
        width: 22px;
        height: 22px;
        // background-image: url(../images/my_account.svg);
        @include media('<=phone') {
            top: 7px;
            left: 20px;
            width: 12px;
            height: 12px;
        }
    }
    &::after {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #fff;
        top: 16px;
        right: 20px;
        @include media('<=phone') {
            display: none;
        }
    }
}

.jcf-select .jcf-select-opener {
    position: absolute;
    text-align: center;
    background: #aaa;
    width: 26px;
    bottom: 0;
    right: 0;
    top: 0;
    display: none;
}

body>.jcf-select-drop {
    position: absolute;
    margin: -1px 0 0;
    z-index: 9999;
    @include media ('<=phone') {
        padding: 8px 0 0;
        width: 100% !important;
        left: 0 !important;
        text-align: center;
    }
}

body>.jcf-select-drop.jcf-drop-flipped {
    margin: 1px 0 0;
}

.jcf-select .jcf-select-drop {
    position: absolute;
    margin-top: 0px;
    z-index: 9999;
    top: 100%;
    left: -1px;
    right: -1px;
}

.jcf-select .jcf-drop-flipped {
    bottom: 100%;
    top: auto;
}


/* multiple select styles */

.jcf-list-box {
    overflow: hidden;
    display: inline-block;
    border: 1px solid #b8c3c9;
    min-width: 200px;
    margin: 0 15px;
}


/* select options styles */

.jcf-list {
    display: inline-block;
    vertical-align: top;
    position: relative;
    background: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: 2px 1px 3px 0px rgba(#000, .3);
    line-height: 14px;
    font-size: 16px;
    width: 100%;
    @include media ('<=phone') {
        border-radius: 0;
        font-size: 14px;
        background: #4f5258;
    }
}

.jcf-list .jcf-list-content {
    vertical-align: top;
    display: inline-block;
    overflow: auto;
    width: 100%;
}

.jcf-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: .6;
    padding: 10px 20px;
    @include media ('<=phone') {
        padding: 15px 20px;
    }
}

.jcf-list ul li {
    overflow: hidden;
    display: block;
}

.jcf-list .jcf-overflow {
    overflow: auto;
}

.jcf-list .jcf-option {
    overflow: hidden;
    cursor: pointer;
    display: block;
    padding: 10px 20px;
    // color: #cecece;
    height: 1%;
    font-family: 'proxima-nova';
    @include media ('<=phone') {
        color: #cecece;
    }
    &.jcf-option-hideme {
        display: none;
    }
}

.jcf-list .jcf-disabled {
    background: #fff !important;
    color: #aaa !important;
}

.jcf-list .jcf-optgroup-caption {
    white-space: nowrap;
    font-weight: bold;
    display: block;
    padding: 5px 9px;
    cursor: pointer;
    color: #000;
}

.jcf-list .jcf-optgroup .jcf-option {
    padding-left: 30px;
}


/* common custom form elements styles */

.jcf-disabled {
    background: #ddd !important;
}

.jcf-focus,
.jcf-focus * {
    border-color: #f00 !important;
}