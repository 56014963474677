.menu-accordion {
    width: 350px;
    position: relative;
    min-height: 100vh;
    @include media ('<=950px') {
        max-width: inherit;
        width: 100%;
        height: inherit;
        min-height: inherit;
    }
    .holder-btns-menu {
        position: relative;
        display: flex;
        justify-content: space-between;
        @include media ('<=950px') {
            padding: 15px 20px;
            background: $gray;
        }
        @include media ('<=phone') {
            padding: 8px 20px;
        }
    }
    .nav-opener {
        top: 0;
        left: 0;
        position: relative;
        display: block;
        max-width: 180px;
        border-radius: 6px;
        padding: 10px 0;
        text-align: center;
        text-transform: uppercase;
        background: $gray;
        color: #fff;
        font-family: $heading-font-family;
        font-weight: 600;
        @include media ('>950px') {
            display: none;
        }
        @include media ('<=950px') {
            font-size: 12px;
            width: 40%;
        }
        @include media ('<=phone') {
            padding: 7px 0 3px 7px;
            letter-spacing: 0.5px;
        }
        // &:before {
        //     content: '';
        //     position: absolute;
        //     top: 8px;
        //     left: 15px;
        //     width: 16px;
        //     height: 19px;
        //     background-image: url(../images/SVG/main-menu.svg);
        //     @include media ('<=950px') {
        //         width: 13px;
        //         height: 16px;
        //     }
        //     @include media ('<=phone') {
        //         top: 4px;
        //     }
        // }
        &:hover {
            background: $bubblegum;
            color: #fff;
        }
        .nav-active & {
            background: $bubblegum;
            &:before {
                display: none;
            }
        }
    }
    .drop {
        display: flex;
        align-items: flex-start;
        max-width: 350px;
        background: #f9f9f9;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-right: 2px solid #aaa;
        @include media ('<=950px') {
            z-index: 2;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            max-width: inherit;
            height: inherit;
            opacity: 0;
            padding: 0;
            visibility: hidden;
            background: $gray;
            ;
            transform: translate(-100%, 0);
            transition: {
                property: opacity, visibility, transform;
                duration: .3s;
                timing-function: linear;
            }
        }
        .nav-active & {
            @include media ('<=950px') {
                opacity: 1;
                visibility: visible;
                transform: translate(0, 0);
            }
        }
        #nav {
            @include media ('<=phone') {
                margin: 0 0 40px
            }
        }
    }
}

.accordion {
    @extend %listreset;
    width: 100%;
    background: #f9f9f9;
    // @include media('<=950px') {
    //     background: #3e4046;
    // }
    .active-i {
        background: $black;
    }
    li {
        position: relative;
        i {
            font-size: 1.5rem;
            margin-right: 2rem;
            min-width: 28px;
            @include media('<=phone') {
                margin: 0 18px 0 0;
            }
        }
        &.active {
            // background: $monstera;
            .opener-slide {
                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    top: 22px;
                    background: $black;
                    width: 12px;
                    height: 2px;
                }
                &:after {
                    transform: rotate(50deg);
                }
                &:before {
                    transform: rotate(-50deg);
                }
            }
        }
        .opener-slide {
            position: relative;
            &:after,
            &:before {
                content: '';
                position: absolute;
                top: 22px;
                background: $black;
                width: 12px;
                height: 2px;
            }
            &:after {
                right: 19px;
                transform: rotate(-50deg);
            }
            &:before {
                right: 27px;
                transform: rotate(50deg);
            }
        }
        a {
            font-family: $heading-font-family;
            font-weight: 600;
            font-size: .9rem;
            padding: 11px 30px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            border-bottom: 1px solid $gray;
            letter-spacing: 1px;
            color: $black;
            @include media('<=1200px') {
                padding: 11px 30px 11px 15px;
            }
            @include media('<=phone') {
                font-size: 12px;
                padding: 9px 30px 8px;
            }
            &:hover {
                background: $black;
                color: $white;
                &:before, &:after {
                    background: $white;
                }
            }
        }
        .slide {
            ////////////////////////////////
            overflow: hidden;
            @extend %listreset;
            background: $black;
            transition: height .3s ease-in;
            li {
                line-height: .5;
                padding: 6px 0;
                line-height: .4;
                a {
                    color: $white;
                    padding: 12px 0 12px 85px;
                    border-bottom: none;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 2px;
                    @include media('<=phone') {
                        font-size: 11px;
                        padding: 10px 0 10px 75px;
                    }
                    &:hover {
                        background: inherit;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.title {
    &.border {
        border-bottom: 1px solid $gray;
        @include media('<=phone') {
            border-bottom: none;
        }
    }
}

.tt-up {
    text-transform: uppercase;
}

.pad-w {
    padding: 0 45px !important;
}

.banner-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $black;
    position: relative;
    @include media('<desktop') {
        display: none;
    }
}

.box-b {
    overflow: hidden;
    height: 111px;
    .holder-img {
        position: relative;
        width: 500px;
        height: 500px;
        overflow: hidden;
        background: $white;
        transform: rotate(59deg) translate(-357px, -82px);
        @include media('<=1090px') {
            transform: rotate(59deg) translate(-374px, -55px);
        }
        img {
            transform: rotate(-59deg) translate(20px, 250px);
            width: 310px;
        }
    }
}

.holder-txt {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 35px;
    top: 40px;
    font-size: 22px;
    // font-family: $heading-font-family-2;
    @include media('<=widescreen') {
        font-size: 18px;
        right: 15px;
    }
    .txt-title,
    a {
        display: block;
        text-transform: uppercase;
    }
    .txt-title {
        color: $white;
        letter-spacing: 1px;
    }
    a {
        font-size: 17px;
        text-decoration: underline;
    }
}

.holder-notification {
    margin: 0 0 25px;
    
    @include media('<=720px') {
        margin: 0 0 10px;
    }
    .notification {
        display: flex;
        margin: 0 0 10px;
        position: relative;
        background: #e9e9e9;
        font-size: 1.2rem;
        font-family: $heading-font-family;
        @include media('<=720px') {
            flex-direction: column;
        }
        &:last-child {
            @include media('<=phone') {
                //display: none;
            }
        }
        .txt {
            width: 70%;
            @include media('<=phone') {
                width: 100%;
            }
        }
        .action {
            width: 30%;
            @include media('<=phone') {
                width: auto;
            }
            a {
                width: 100%;
            }
        }
        &.announcement {
            background: #e9e9e9;
            .icon-notice {
                background: $monstera;
                color: $white;
            }
            .content-text {
                .btn-cart {
                    background: $monstera;
                    color: $white;
                }
            }
        }
        &.reminder {
            background: #e9e9e9;
            .icon-notice {
                background: $flamingo;
                color: $black;
            }
            .content-text {
                .btn-cart {
                    background: $flamingo;
                    color: $black;
                }
            }
        }
        &.alert {
            background: #e9e9e9;
            .icon-notice {
                background: $poppy;
                color: $powder;
            }
            .content-text {
                .btn-cart {
                    background: $black;
                    color: $powder;
                }
            }
        }
    }
}

.icon-notice {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    padding: 37px 41px;
    font-size: 14px;
    text-transform: uppercase;
    color: $white;
    position: relative;
    font-family: $heading-font-family !important;

    @include media('<=720px') {
        max-width: inherit;
        flex-direction: row;
        width: 100%;
        font-size: 15px;
        padding: 5px;
        letter-spacing: 1px;
    }
    .hold-l {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        font-weight: 600;
        align-items: center;
        @include media('<=720px') {
            width: 100%;
            flex-direction: row;
            position: relative;
            transform: inherit;
            left: 0;
            top: 0;

        }
        i {
            font-size: 2rem;
            margin-bottom: .5rem;
            @include media ('<=phone') {
                font-size: 1rem;
                width: 24px;
            }
        }
    }
    img {
        width: 25px;
        margin: 0 0 6px;
        @include media('<=720px') {
            width: 22px;
            margin: 2px 15px 2px 2px;
        }
    }
    p {
        margin: 0;
        font-size: .7rem;
        font-weight: 600 !important;
    }
    &.min {
        padding: 23px 41px;
        .hold-l img {
            margin-bottom: 0;
        }
    }
}

.icon-sm {
    height: 1.2rem;
    width: 1.2rem;
}

.content-text {
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: space-between;
    padding: 8px 16px 8px 16px;
    @include media('<=phone') {
        padding: 5px 12px 12px;
        width: 100%;
        flex-direction: column;
    }
    .txt {
        @include media('<=phone') {
            font-size: 13px;
            width: 100%;
            margin-bottom: 1rem;
        }
        h3 {
            margin: 0;
            strong {
                font-size: 20px;
                text-transform: uppercase;
                @include media('<=phone') {
                    font-size: 15px;
                }
            }
        }
        .text-span {
            text-transform: uppercase;
            @include media('<=phone') {
                font-size: 14px;
            }
        }
    }
    .btn-cart {
        // width: 170px;
        min-width: 170px;
        text-align: center;
        display: block;
        @include media('<=720px') {
            // position: absolute;
            // top: 5px;
            // right: 5px;
            // border: 1px solid $white;
            font-size: 1rem;
            // width: 150px;
            padding: 3px 5px;
        }
        &.leter-s {
            letter-spacing: -.5px;
        }
    }
    p {
        margin: 0;
    }
    .dismiss {
        margin-top:6px;
        font-size: .8rem;
        a {
            color: #555;
        }
    }
}

.choice-block {
    padding: 20px 0px;
    margin: 0 0 15px;
    background: $white;
    @include media('<=phone') {
        padding: 16px 0px;
        margin: 0 0 12px;
    }
    .row-choice {
        // display: flex;
        // justify-content: space-between;
        margin-bottom: 2rem;
        // padding: 2rem 1rem;
        // background: #e9e9e9;
        @include media ('<=720px') {
            // flex-direction: column;
        }
        
        .title-box {
            margin: 0;
            display: flex;
            padding: 1rem;
            text-transform: uppercase;
            background: $black;
            color:$white;
            // border-bottom: 1px solid $gray;
            @include media('<=phone') {
                margin: 0 0 10px;
            }
            h2 {
                font-size: 2rem;
                margin-bottom: 0;
                @include media('<=phone') {
                    font-size: 14px;
                    margin: 0 0 10px;
                }
            }
            i {
                font-size: 20px;
                margin: 0 15px 0 0;
                @include media('<=phone') {
                    font-size: 14px;
                    margin: 0 10px 0 0;
                }
            }
        }
    }
}

.buttons-holder {
    // padding: 0 20px;
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    background: #e9e9e9;
    padding: 2rem 1rem;
    @include media('<=widescreen') {
        // padding: 0;
    }
    @include media('<=720px') {
        max-width: 400px;
        margin: 0 auto;
        flex-direction: column;
    }
    @include media('<=phone') {
        padding: 15px;
    }
    .col {
        width: 46%;
        @include media('<=720px') {
            width: 100%;
        }
    }
    a {
        margin: 0 8px 7px;
        font-size: 15px;
        // letter-spacing: 1px;
        width: 45%;
        @include media('<=phone') {
            width: 100%;
            font-size: 14px;
            padding: 6px 10px;
        }
        &.btn-all {
            font-weight: 400;
            padding: .5rem 1rem;
            strong {
                font-size: 1.2rem;
                font-weight: 600;
            }
        }
    }
}

.post-block {
    display: flex;
    margin: 0 -7px;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    .cart {
        padding: 0 7px;
        margin-bottom: 1rem;
        //max-width: 211px;
        //min-height: 234px;
        display: flex;
        flex-direction: column;
        @include media('<=phone') {
            //max-width: inherit;
            //min-height: inherit;
        }
        &:first-child {
            @include media('<=phone') {
                //display: none;
            }
        }
        .head-title {
            // text-align: center;
            padding: 7px 20px;
            line-height: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 60px;
            @include media('<=phone') {
                padding: 10px 20px;
            }
            h3 {
                // color: $white;
                font-size: 20px;
                text-transform: uppercase;
                margin: 0;
            }
            span.price {
                color: #fff;
                font-size: 1.2rem;
                font-weight: 400;
            }
        }
        .box-text {
            padding: 35px 10px;
            font-size: 14px;
            text-align: center;
            background: $white;
            letter-spacing: 1px;
            line-height: 1.3;
            display: flex;
            font-weight: 400;
            flex-direction: column;
            width: 100%;
            flex-grow: 1;
            @include media('<=phone') {
                padding: 15px 12px;
            }
            p {
                // margin: 0 0 28px;
                margin: 0 0 10px;
                @include media('<=phone') {
                    // margin: 0 0 30px;
                    margin: 0 0 15px;
                }
            }
            a {
                
                @include media('<=phone') {
                    //max-width: 150px;
                    margin: 0 auto;
                    //padding: 8px 5px;
                    font-size: 13px;
                }
            }
        }
    }
}

.support-block {
    position: relative;
    padding: 15px 0;
    background: $white;
    margin: 0 0 15px;
}

.title-box-second {
    h2 {
        font-size: 20px;
        margin: 0;
        @include media('<=phone') {
            font-size: 14px;
        }
    }
}

.title-h2-box {
    text-transform: uppercase;
}

.left-line {
    width: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    &.thin {
        width: 10px;
    }
}

.row-text-block,
.row-text-block-line,
.renew-or-purchase {
    padding: 0 30px 0 42px;
    position: relative;
}

.row-text-block,
.row-text-block-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    .btn-all {
        padding: 8px 35px;
        text-transform: uppercase;
        font-size: 15px;
        margin: 0;
        @include media('<=560px') {
            font-size: 14px;
            padding: 5px 35px;
        }
    }
}

.row-text-block {
    transition: margin-bottom .3s;
    @include media('<=375px') {
        flex-direction: column;
    }
}

.expanded .row-text-block {
    margin: 0 0 35px;
    &::before {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        height: 1px;
        width: 100%;
        background: $gray;
    }
}

.row-text-block-line {
    margin: 0 0 35px;
    &::before {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        height: 1px;
        width: 100%;
        background: $gray;
    }
    @include media('<=phone') {
        flex-direction: column;
    }
}

.col-per {
    @include media('<=550px') {
        margin: 0 0 15px 15px;
        width: 100% !important;
    }
}

.renew-or-purchase {
    .button-box {
        display: flex;
        justify-content: flex-start;
        margin: 0 0 15px;
        
        @include media('<=560px') {
            flex-direction: column;
        }
        .btn-all {
            // padding: 8px 22px;
            padding: 8px 25px;
            font-size: 15px;
            text-transform: uppercase;
            @include media('<=560px') {
                margin: 0 0 15px;
                font-size: 14px;
            }
        }
    }
    .text {
        max-width: 645px;
        margin: 0 0 30px;
        font-weight: 400;
        @include media('<=phone') {
            font-size: 14px;
        }
    }
}

.item-block {
    position: relative;
    background: $white;
    padding: 17px 0;
    margin: 0 0 15px;
}

.block-p {
    padding: 20px 0 !important;
}

.holder-container {
    display: flex;
    justify-content: space-between;
    @include media('<tablet') {
        flex-direction: column-reverse;
    }
}

.content-main {
    width: 67%;
    @include media('<tablet') {
        width: 100%;
    }
    h2 {
        font-size: 18px;
        text-transform: lowercase;
        strong {
            text-transform: uppercase;
        }
    }
    .row-text-block {
        padding: 0 20px 0 42px;
    }
    .btn-all {
        padding: 8px 25px;
    }
}

.aside {
    margin: -35px 0 0;
    padding: 0 0 0 15px;
    @include media('<tablet') {
        margin: 0 0 15px;
        padding: 0;
        display: flex;
        min-height: 100%;
    }
    @include media('<=phone') {
        display: block;
    }
    .banner-aside {
        max-width: 270px;
        @include media('<tablet') {
            background: $white;
        }
        @include media('<=phone') {
            max-width: inherit;
        }
        img {
            width: 100%;
        }
    }
    .side-txt {
        padding: 20px;
        background: $white;
        @include media('<tablet') {
            width: 63%;
        }
        @include media('<=phone') {
            width: 100%;
            padding: 20px;
        }
        h2 {
            font-size: 18px;
            margin: 0 0 20px;
            @include media('<tablet') {
                font-size: 16px;
                margin: 0 0 15px;
            }
        }
        .in-text {
            font-weight: 400;
            @include media('<tablet') {
                display: flex;
                font-size: 14px;
            }
            @include media('<=670px') {
                display: block;
            }
        }
        address {
            font-style: normal;
            margin: 0 0 25px;
            @include media('<tablet') {
                width: 100%;
            }
            @include media('<=670px') {
                margin: 0 0 10px;
            }
            @include media('<=phone') {
                margin: 0 0 15px;
            }
            p {
                margin: 0;
            }
        }
        .time {
            @extend %listreset;
            @include media('<tablet') {
                width: 100%;
                padding: 0 0 0 15px;
            }
            @include media('<=670px') {
                padding: 0;
            }
            li {
                margin: 0 0 20px;
                @include media('<=670px') {
                    margin: 0 0 5px;
                }
                @include media('<=phone') {
                    margin: 0 0 15px;
                }
                &:last-child {
                    margin: 0;
                }
                p {
                    margin: 0;
                }
            }
        }
    }
}

.all-width {
    width: 100% !important;
    @include media('<=tablet') {
        width: 90% !important;
        margin: 0 auto;
    }
    @include media('<=phone') {
        width: 100% !important;
    }
    .container {
        margin: 0 auto !important;
        .line-text {
            margin: 0 0 35px !important;
            
        }
    }
}

.all-width-2 {
    width: 100% !important;
}

.color-box {
    background: $white;
}

.form-holder {
    display: flex;
    flex-direction: row;
    margin: 0 0 40px;
    @include media('<=tablet') {
        flex-direction: column;
        align-items: center;
    }
    .form-box {
        display: flex;
        width: 50%;
        padding: 15px;
        @include media('<=tablet') {
            width: 100%;
        }
        .color-box {
            flex: 1;
        }
        &.new-account-form {
            @include media('<=tablet') {
                //order: 2;
            }
        }
        
        .form-title {
            padding: 10px 40px;
            margin: 0 0 20px;
            color: $white;
            h2 {
                font-size: 18px;
                margin: 0;
                letter-spacing: .1px;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
        form {
            min-height: 268px;
            padding: 0 40px 30px;
            display: flex;
            flex-direction: column;
            .input-row {
                display: flex;
                flex-direction: column;
                margin: 0 0 18px;
                .hold {
                    display: flex;
                    justify-content: space-between;
                    .check-hold {
                        label {
                            font-weight: 400;
                            padding: 0 0 0 6px;
                        }
                    }
                }
                label {
                    font-size: 15px;
                    font-weight: 700;
                    margin: 0 0 2px;
                    
                }
            }
            .marg-b {
                margin: 0 0 75px;
            }
            .p-last {
                margin: 0 0 35px;
            }
            .sum-btn {
                letter-spacing: 1px;
            }
            .reset {
                color: $monstera;
                border-bottom: 1px solid $monstera;
                // width: 130px;
                margin: 20px auto 20px;
                font-size: 17px;
                letter-spacing: 1px;
            }
            .login {
                text-transform: uppercase;
            }
        }
    }
}

.some-text {
    // letter-spacing: -.16px;
    margin: 0 0 30px;
    font-weight: 400;
    @include media('<=phone') {
        font-size: 14px;
    }
    p {
        margin: 0 0 20px;
    }
    .some-list {
        @extend %listreset;
        list-style-type: disc;
        padding: 0 0 0 15px;
        li {
            margin: 0 0 1px;
        }
    }
}

.purchase-open {
    padding: 15px 0;
    background: $white;
    .row-text-block-line {
        padding: 0 10px 20px 40px;
        margin: 0 0 30px;
        @include media('<=650px') {
            flex-direction: column;
            align-items: flex-start;
            padding: 0 10px 20px 20px;
        }
        &::before {
            bottom: 0;
        }
        .col-per {
            padding: 0 10px 0 0;
            @include media('<=650px') {
                margin: 0 0 15px;
            }
            h2 {
                @include media('<=tablet') {
                    font-size: 14px;
                }
            }
            p {
                margin: 0;
            }
        }
        .col-box {
            display: flex;
            align-items: center;
            @include media('<=650px') {
                flex-direction: column;
            }
            .number-box {
                display: flex;
                align-items: center;
                @include media('<=650px') {
                    margin: 0 0 15px;
                }
                h3 {
                    margin: 0;
                    font-size: 14px;
                }
                input[type="number"] {
                    background: $white;
                    outline: none;
                    border: 1px solid $gray;
                    text-align: right;
                    padding: 5px 10px 5px 25px;
                    margin: 0 0 0 10px;
                    max-width: 60px;
                }
            }
            .btn-all {
                padding: 8px 25px;
                margin: 0 0 0 45px;
                @include media('<=desktop') {
                    margin: 0 0 0 25px;
                }
                @include media('<=650px') {
                    margin: 0;
                }
            }
        }
    }
    .year {
        font-size: 16px;
        font-style: italic;
        color: $monstera;
    }
}

.ticket-content {
    padding: 0 45px;
    padding: 0 20px;
    font-weight: 400;
    .ticket-block {
        letter-spacing: -.1px;
        margin: 0 0 40px;
        h3 {
            font-size: 16px;
            font-weight: 700;
            margin: 0 0 20px;
        }
        p {
            margin: 0 0 20px;
        }
        .price {
            display: block;
            margin: 0 0 3px;
        }
        .bottom-p {
            margin: 0 0 20px;
        }
    }
}

.status {
    .item-block {
        padding: 20px 0 10px;
        &.min {
            padding: 4px 0 0;
        }
    }
    .col-per {
        margin: 0 15px 0;
        width: 70%;
        @include media('<=550px') {
            margin: 0 0 15px 0;
            width: 100%;
        }
    }
    .block-btn {
        @include media('<=550px') {
            margin: 0 auto 15px;
            width: 100%;
        }
        width: 30%;
        .btn {
            text-align: center;
        }
    }
}

.status-in {
    .item-block {
        padding: 0 0 35px;
        .row-text-block {
            @include media('<=650px') {
                margin: 0 0 15px;
            }
            .col-per {
                margin: 0 15px 0 0;
                width: 70%;
            }
            .block-btn {
                font-size: 1rem;
                @include media('<=550px') {
                    margin: 0 auto;
                }
            }
            .title-box-second {
                h2 {
                    margin: 0 0 10px;
                }
            }
        }
    }
}

.holder-btn-bg {
    display: flex;
    padding: 0 42px;
    @include media('<=670px') {
        justify-content: space-between;
    }
    @include media('<=650px') {
        flex-direction: column;
    }
    .btn-all {
        margin: 0 8px 0 0;
        display: flex;
        align-items: center;
        // padding: 0 20px;
        text-transform: uppercase;
        @include media('<=670px') {
            margin: 0;
        }
        @include media('<=650px') {
            // padding: 8px 10px;
            margin: 0 0 15px;
            justify-content: center;
        }
        i {
            font-size: 18px;
            margin: 0 12px 0 0;
            &.fas, &.far {
                position: relative;
                top: -2px;
            }
        }
        p {
            margin: 0;
        }
        &.whidth-line {
            padding: 8px 35px;
            max-width: 200px;
            @include media('<=650px') {
                max-width: inherit;
            }
            &:last-child {
                padding: 8px 40px;
                margin: 0;
            }
        }
    }
}

.status,
.status-in {
    .top-head {
        padding: 6px 42px;
        background: $gray;
        margin: 0 0 28px;
        h2 {
            font-size: 20px;
            font-weight: 700;
            margin: 0;
            color: $white;
            text-transform: uppercase;
        }
    }
    .row-text-block {
        padding: 0 42px;
        align-items: flex-start;
        @include media('<=550px') {
            flex-direction: column;
        }
    }
    .col-per {
        .year {
            display: block;
            font-family: $heading-font-family;
            font-size: 1.2rem;
            &.bot-m {
                margin: 0 0 5px;
            }
            &.small {
                font-size: 14px;
                margin: 0 0 40px;
            }
        }
        .last-child {
            margin: 0 0 15px;
        }
        .btn-all {
            //max-width: 105px;
            padding: 8px 20px;
            //display: inline-block;
            font-size: 15px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            //margin: 0;
            letter-spacing: .4px;
            i {
                font-size: 18px;
                margin: 0 12px 0 0;
            }
            p {
                margin: 0;
            }
        }
    }
}

.block-btn {
    // border: 1px solid $monstera; //$gray
    border: 1px solid $gray;
    font-family: $heading-font-family;
    @include media('<=550px') {
        flex-direction: column;
    }
    .status-choose {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 700;
        padding: 10px 10px;
        p {
            margin: 0;
        }
    }
    .btn,
    .btn-in {
        display: block;
        text-align: center;
        font-weight: 400;
        // white-space: nowrap;
        @include media('<=550px') {
            text-align: center;
        }
    }
    .btn {
        padding: 10px 38px;
        margin: 0 -1px;
        font-size: 1rem;
        font-weight: 600;
    }
    .btn-in {
        padding: 10px 40px;
        margin: 0 -1px;
    }
}

.new-reg {
    margin: 0 0 25px !important;
    .title {
        padding: 10px 0 0;
        h2 {
            margin: 0 0 5px;
        }
    }
}

.box-notific {
    margin: 0 0 25px !important;
}

.step-block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3px;
    .step-cart {
        padding: 0 3px;
        text-align: center;
        width: 25%;
        min-width: 210px;
        margin: 0 0 20px;
        @include media('<=tablet') {
            width: 50%;
        }
        @include media('<=phone') {
            width: 100%;
        }
    }
    .color-b {
        .circle {
            width: 75px;
            height: 75px;
            margin: 0 auto 10px;
            &::before,
            &:after {
                display: none;
            }
        }
    }
    &.color-b {
        .top-head {
            padding: 6px 10px;
            h2 {
                font-size: 20px;
                letter-spacing: 2px;
            }
        }
    }
    .top-head {
        color: $white;
        padding: 8px 10px;
        h2 {
            text-transform: uppercase;
            margin: 0;
            font-size: 18px;
        }
    }
}

.font-b {
    font-weight: 700;
}

.step-content {
    background: $white;
    padding: 10px 20px;
    text-align: center;
    min-height: 191px;
    position: relative;
    .circle {
        color: $gray;
    }
    .color-orange {
        margin: 0 0 15px;
    }
    .txt {
        font-size: 15px;
        display: block;
        margin: 0 0 5px;
        padding: 0 12px;
        &.big-m {
            margin: 0 0 15px;
        }
    }
    .btn-all {
        position: absolute;
        padding: 8px 10px;
        top: 40%;
        left: 50%;
        width: 85%;
        transform: translate(-50%, -50%);
    }
    .block-incomplete {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
    }
    .img-box {
        margin: 0 auto 7px;
        img {
            background: $monstera;
            border-radius: 50%;
            overflow: hidden;
            width: 38px;
            height: 38px;
            padding: 9px;
        }
    }
    .big-text {
        display: block;
        font-size: 19px;
        line-height: 1;
    }
}

.circle {
    position: relative;
    border: 2px solid $gray;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 auto 10px;
    &::before,
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        width: 60px;
        background: $gray;
    }
    &::before {
        left: -70px;
    }
    &:after {
        right: -70px;
    }
}

.stile-txt {
    font-style: italic;
}

.padd-b {
    margin: 0 0 10px !important;
}

.no-title {
    padding: 35px 20px 15px !important;
    .new-btn {
        max-width: 168px !important;
        margin: 0 auto;
    }
}

.choose-content {
    display: flex;
    margin: 0 -10px 20px;
    flex-wrap: wrap;
    .cart {
        width: 50%;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        @include media('<=widescreen') {
            width: 100%;
            margin: 0 0 20px;
        }
        @include media('<=950px') {
            width: 50%;
            margin: 0;
        }
        @include media('<=tablet') {
            width: 100%;
            margin: 0 0 20px;
        }
        .head-title {
            color: $white;
            padding: 14px 0;
            h3 {
                font-size: 20px;
                letter-spacing: .8px;
                text-align: center;
                text-transform: uppercase;
                margin: 0;
                font-weight: 700;
            }
        }
        .box-text {
            display: flex;
            flex-direction: column;
            text-align: center;
            background: $white;
            position: relative;
            font-size: 17px;
            width: 100%;
            flex-grow: 1;
            padding: 20px 30px !important;
            p {
                margin: 0 0 20px;
            }
        }
        .btn-all {
            display: inline-block;
            padding: 8px 35px;
            font-size: 18px;
            font-weight: 700;
            max-width: 310px;
            @include media('<=tablet') {
                padding: 9px 20px;
            }
            @include media('<=430px') {
                font-size: 14px;
            }
        }
    }
}

.hol-link-cart {
    margin: auto 0 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.market-block {
    .head-title {
        text-align: center;
        padding: 17px 20px;
        line-height: 1;
        @include media('<=phone') {
            padding: 10px 20px;
        }
        h3 {
            color: $white;
            font-size: 20px;
            text-transform: uppercase;
            margin: 0;
        }
    }
    .box-text {
        background: $white;
        padding: 35px 30px 15px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        p {
            margin: 0 0 55px;
        }
        .btn-all {
            padding: 8px 10px;
            max-width: 150px;
            margin: 0 auto;
            font-size: 18px;
            text-transform: capitalize;
        }
    }
}