#header {
    padding: 10px 0;
    background: $black;
    @include media ('<=phone') {
        padding: 15px 0 10px;
    }
    .container {
        width: 100%;
        max-width: inherit;
        position: relative;
    }
}

.resize-active {
    * {
        transition: none !important;
    }
}

.top-header {
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    @include media ('<tablet') {
        padding: 0;
    }
}

.logo {
    a {
        display: flex;
        align-items: center;
        img {
            width: 150px;
            @include media('<tablet') {
                display: none;
            }
        }
        h1 {
            text-transform: uppercase;
            margin: 10px 0 0 28px;
            letter-spacing: 1.3px;
            font-weight: 600;
            color: $white;
            // font-family: $body-font-family;
            @include media('<=desktop') {
                margin: 0 0 0 15px;
            }
            @include media ('<tablet') {
                margin: 0;
            }
            @include media ('<=phone') {
                letter-spacing: .5px;
            }
            .text-green {
                font-weight: 400;
                font-size: 25px;
                margin: 0 0 0 5px;
                @include media('<=desktop') {
                    font-size: 18px;
                }
                @include media ('<=phone') {
                    font-size: 14px;
                }
            }
        }
    }
}

.opener {
    top: -7px;
    right: 10px;
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 5;
    @include media ('>=tablet') {
        display: none;
    }
    @include media ('<=phone') {
        top: -8px;
        right: 13px;
        display: block;
        width: 35px;
        height: 30px;
    }
    .active & {
        span {
            opacity: 0;
        }
        &:before,
        &:after {
            transform: rotate(45deg);
            top: 50%;
            left: 15%;
            right: 15%;
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    span,
    &:before,
    &:after {
        position: absolute;
        background: $white;
        top: 50%;
        left: 16%;
        right: 16%;
        height: 3px;
        margin-top: -2px;
        transition: all .3s ease-out;
    }
    &:before,
    &:after {
        content: '';
        top: 30%;
    }
    &:after {
        top: 70%;
    }
}

.drop {
    display: flex;
    align-items: center;
    @include media ('<tablet') {
        position: absolute;
        z-index: 3;
        top: 100%;
        left: 0;
        width: 100%;
        opacity: 0;
        padding: 0;
        visibility: hidden;
        background: $black;
        transform: translate(100%, 0);
        transition: {
            property: opacity, visibility, transform;
            duration: .3s;
            timing-function: linear;
        }
    }
    @include media ('<=desktop') {
        justify-content: space-between;
    }
    .active & {
        @include media ('<=tablet') {
            padding: 10px 20px;
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
        }
        @include media ('<=phone') {
            padding: 12px 12px 12px 20px;
        }
    }
}

.top-link-holder {
    display: flex;
    align-items: center;
    @include media ('<=tablet') {}
}

#nav {
    @extend %listreset;
    li {
        &:first-child {
            .nav-link {
                margin: 0 0 10px;
            }
        }
        .nav-link {
            color: $white;
            font-size: 13px;
            display: flex;
            align-items: center;
            font-family: $heading-font-family;
            font-weight: 600;
            text-transform: uppercase;
            @include media ('<=phone') {
                font-size: 12px;
            }
            margin: 0 0 5px;
            .icon-img {
                font-size: 12px;
                width: 18px;
                height: 18px;
                margin: 0 15px 0 0;
                @include media ('<=phone') {
                    width: 16px;
                }
                img {
                    display: block;
                }
            }
        }
    }
}

.head-login {
    .nav-link {
        margin: 0 !important;
    }
    .top-header {
        padding: 0 0 0 10px !important;
    }
    .first {
        margin: 0 0 0 40px !important;
        border-right: none !important;
        padding: 10px 8px 10px 25px !important;
    }
}

.big-link {
    
    display: block;
    color: $white;
    &.first {
        padding: 10px 25px;
        border-left: 1px solid $gray;
        border-right: 1px solid $gray;
        // margin: 0 30px;
        margin: 0 25px 0 20px;
        @include media('<=desktop') {
            margin: 0 10px;
        }
        @include media('<=phone') {
            padding: 10px 15px;
            margin: 0 15px;
        }
        .icon-img {
            img {
                margin: 0;
            }
        }
    }
    .radius {
        width: 37px;
        height: 37px;
        border: 1px solid $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media('<=phone') {
            width: 28px;
            height: 28px;
        }
    }
    .icon-img {
        text-align: center;
        max-width: 75px;
        letter-spacing: -1.6px;
        text-transform: uppercase;
        font-size: 13px;
        font-family: $body-font-family !important;
        // font-family: $base-font-sans-serif !important;
        @include media('<=phone') {
            letter-spacing: 0;
            font-size: 11px;
        }
        img {
            width: 18px;
            margin: 0 0 10px;
            @include media('<=phone') {
                width: 14px;
            }
        }
        p {
            margin: 0;
            font-family: $heading-font-family;
        text-transform: uppercase;
        font-weight: 600;
        font-size: .8rem;
        }
    }
}