@font-face {
    font-family: 'icomoon';
    src: url('../../../portal/fonts/icomoon.eot');
    src: url('../../../portal/fonts/icomoon.eot') format('embedded-opentype'), url('../../../portal/fonts/icomoon.ttf') format('truetype'), url('../../../portal/fonts/icomoon.woff') format('woff'), url('../../../portal/fonts/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-mark-as-favourite-star:before {
    content: "\e900";
}

.icon-pencil:before {
    content: "\e901";
}