body {
    // font-weight: 600;
    // background: $white;
}



#wrapper {
    overflow: hidden;
    position: relative;
}

.container {
    max-width: 895px;
    padding: 0 20px;
    margin: 0 auto;
    p {
        font-weight: 200;
        
    }
}

.btn-all {
    font-size: 1rem;
    padding: .5rem 1.5rem;
    // white-space: nowrap;
    border-radius: 30px;
    display: inline-block;
    text-align: center;
    font-weight: 400;
    color: #fff;
    transition: filter .3s;
    margin: 0 auto;
    font-family: $heading-font-family;
    text-transform: uppercase;
    font-weight: 600;
    @include media('<=1240px') {
        font-size: 16px;
    }
    &:hover, &:focus {
        filter: brightness(1.1);
    }
    strong {
        font-size: 1.4rem;
    }
}

.btn-title {
    font-size: 15px;
    padding: 10px 25px;
    white-space: nowrap;
    border-radius: 30px;
    display: inline-block;
    text-transform: uppercase;
    @include media('<=1240px') {
        font-size: 14px;
    }
}

.btn-outline {
    background: transparent;
    border: 1px solid $black;
    color: $black;
    &.orange {
        border-color: $sunflower;
        color: $sunflower;
    }
    &.pink {
        border-color: $flamingo;
        color: $flamingo;
    }
    &.poppy, &.red {
        border-color: $poppy;
        color: $poppy;
    }
}

.btn-inline {
    margin: 0 .25rem;
}

.big-size {
    h2 {
        font-size: 30px;
        margin: 0 0 30px;
        @include media('<=phone') {
            font-size: 18px;
        }
    }
}



// Color and background
.white-color {
    color: $white;
}

.bg-smoke {
    background-color: #f8f0e5;
}

.text-green {
    color: $monstera !important;
}

.color-text-red {
    color: $poppy !important;
}

.bg-red, .bg-poppy {
    background: $poppy !important;
    color: $white;
}

.color-gray-3 {
    color: $gray;
}

.bg-orange, .bg-sunflower{
    background: $sunflower !important;
    color: $black;
}

.color-orange {
    color: $sunflower;
}

.bg-lite-orange {
    background: $sunflower !important;
}

.color-green {
    color: $monstera;
}

.color-green-2 {
    color: $monstera;
}

.bg-green, .bg-monstera {
    background: $monstera !important;
    color: $white;
}

.bg-green-2 {
    background: $monstera !important;
    color: $white;
}

.bg-green-3 {
    background: $monstera !important;
    color: $white;
}

.bg-green-4 {
    background: $monstera !important;
    color: $white;
}

.color-gray-7 {
    color: $gray;
}

.bg-pink, .bg-flamingo {
    background: $flamingo !important;
    color: $black;
}

.bg-pink-2 {
    background: $flamingo !important;
    color: $black;
}

.bg-black-4 {
    background: $black !important;
}

.bg-black-5 {
    background: $black !important;
}

.bg-light-blue, .bg-powder {
    background: $powder !important;
    color: $black;
}
.bg-baby-blue {
    background: $powder !important;
    color: $black;
}
.bg-black {
    background: $black !important;
    color: $white;
}



.new-block {
    .line-text {
        margin: 0 0 20px !important;
        border-bottom: inherit !important;
    }
}

.content-holder {
    display: flex;
    @include media ('<=950px') {
        display: block;
    }
    .menu-accordion {
        @include media ('<=950px') {
            width: 100%;
        }
    }
}

.block,
.block-list {
    width: 76%;
    padding: 50px 0;
    @include media ('<=950px') {
        width: 100%;
    }
    .container {
        @include media('<=1440px') {
            margin: 0 auto 0 10%;
        }
        @include media('<1440px') {
            margin: 0 auto;
        }
    }
    .line-text {
        display: flex;
        justify-content: space-between;
        position: relative;
        @include media('<=tablet') {
            position: inherit;
        }
        @include media('<=phone') {
            margin: 0 0 8px;
            // border-bottom: 1px solid $gray;
        }
        &.new-m {
            margin: 0 0 35px;
            @include media('<=phone') {
                margin: 0 0 8px;
            }
        }
        .title {
            width: 76%;
            .m-top {
                margin: 13px 0 0; //////////////////////////////////////////////////
            }
            h2 {
                font-size: 2rem;
            }
        }
        .row {
            position: absolute;
            top: 0;
            right: 0;
            @include media('<=phone') {
                top: 70px;
                right: 15px;
            }
            &.btn-b {
                position: relative;
                @include media('<=phone') {
                    position: absolute;
                }
            }
            ul {
                margin: 0;
            }   
        }
    }
}

.drop-down {
    position: relative;
    .drop-opener {
        padding: 10px 15px;
        background: $black;
        min-width: 180px;
        border-radius: 6px;
        display: block;
        z-index: 2;
        color: #fff;
        font-family: $heading-font-family;
        text-transform: uppercase;
        font-weight: 600;
        font-size: .9rem;
        @include media('<=phone') {
            font-size: .8rem;
            padding: 6px 15px 5px 15px;
            position: absolute;
            right: 15px;
            // min-width: 125px;
            text-align: center;
            background: $black;
        }
        &::before,
        &::after {
            content: '';
            z-index: 1;
            position: absolute;
        }
        &::before {
            // top: 8px;
            // left: 12px;
            // width: 22px;
            // height: 22px;
            // background-image: url(../../../images/svg/my_account.svg);
            @include media('<=phone') {
                top: 7px;
                left: 20px;
                width: 12px;
                height: 12px;
            }
        }
        &::after {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid $white;
            top: 16px;
            right: 20px;
            @include media('<=phone') {
                display: none;
            }
        }
    }
    &.active {
        .drop-box {
            opacity: 1;
            visibility: visible;
        }
    }
    .drop-box {
        top: 40px;
        opacity: 0;
        visibility: hidden;
        background: #fff;
        position: absolute;
        vertical-align: top;
        width: 100%;
        z-index: 2;
        font-size: 16px;
        box-shadow: 2px 1px 3px 0 rgba(#000, .3);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        @include media('<=phone') {
            position:fixed;
            top: 80px;
            left: 0;
            background: #4f5258;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        ul {
            @extend %listreset;
            text-align: left;
            width: 100%;
            padding: 10px 30px;
            text-align: left;
            width: 100%;
            line-height: 0.5;
            @include media('<=tablet') {
                // text-align: center;
                padding: 10px 20px;
            }
            @include media('<=phone') {
                text-align: center;
            }
            li {
                a {
                    padding: 10px 0;
                    display: block;
                    color: #3e4046;
                    @include media('<=phone') {
                        color: #cecece;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.big-t {
    padding: 6px 10px !important;
    h2 {
        font-size: 20px !important;
    }
}

.m-top-m {
    margin: 13px 0 25px !important;
}

.m-top-m-2 {
    margin: 13px 0 10px !important;
}

.block {
    .line-text {
        margin: 0 0 12px;
    }
    .banner-box {
        margin: 0 0 10px;
    }
}

.block-list {
    .post-block {
        margin: 0 -7px 30px;
        display: flex;
        flex-wrap: wrap;
        .cart {
            display: flex;
            flex-direction: column;
            // flex-wrap: wrap;
            //width: 25%;
            max-width: inherit;
            @include media('<=widescreen') {
                //width: 50%;
                margin: 0 0 15px;
            }
            @include media('<=950px') {
                //width: 25%;
                margin: 0;
            }
            @include media('<=tablet') {
                //width: 50%;
                margin: 0 0 15px;
            }
            @include media('<=phone') {
                //width: 100%;
            }
            .box-text {
                display: flex;
                flex-direction: column;
                // padding: 15px 20px;
                padding: 15px 18px;
                position: relative;
                width: 100%;
                flex-grow: 1;
                strong {
                    text-transform: uppercase;
                }
                .btn-all {
                    margin: auto auto 0;
                    max-width: 162px;
                }
            }
        }
    }
    .line-text {
        margin: 0 0 20px;
        .title {
            h2 {
                font-size: 30px;
                @include media('<=tablet') {
                    font-size: 18px;
                }
            }
        }
    }
    .banner-box {
        margin: 0 0 20px;
    }
    .holder-notification {
        margin: 0 0 16px;
        .notification {
            background: $white;
            display: flex;
            
            .icon-notice {
                padding: 15px 20px;
                // padding: 15px 18px;
                img {
                    margin: 0;
                }
            }
            .content-text {
                padding: 18px 20px;
                font-size: 16px;
                .text-span {
                    text-transform: inherit;
                    font-weight: 600;
                }
                .txt {
                    font-weight: 400;
                }
            }
            
        }
    }
}

.holder-select {
    position: relative;
    @include media('<=phone') {
        position: inherit;
    }
    &::before {
        content: 'Chris Botvidson';
        position: absolute;
        top: -24px;
        right: -4px;
        font-size: 18px;
        font-weight: 700;
        @include media('<=phone') {
            display: none;
        }
    }
}

.panel {
    margin-bottom: 22px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 2px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    padding: 20px 30px;
    .panel-heading {
        padding: 10px 15px;
        border-bottom: 1px solid transparent;
        border-top-right-radius: 1px;
        border-top-left-radius: 1px;
    }
    p:last-child {
        margin-bottom: 0;
    }
}
.panel-default {
    border-color: #ddd;
    & > .panel-heading {
        color: #3E4046;
        background-color: #f5f5f5;
        border-color: #ddd;
        .panel-title {
            margin-bottom: 0;
        }
    }
    .panel-body {
        padding: 15px;
        .form-row {
            margin-bottom: 1rem;
            &.flex {
                align-items: center;
                label {
                    width: 25%;
                    text-align: right;
                    padding-right: 2rem;
                }
            }
        }
        .form-control {
            display: block;
            //width: 100%;
            flex: 1;
            height: 36px;
            padding: 6px 12px;
            font-size: 16px;
            line-height: 1.428571429;
            color: #6b6a6a;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            border-radius: 2px;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
            -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
            transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        }
    }
}

.panel-alert {
    border-left: 4px solid $bubblegum;
    background: $bubblegum;
    color: $white;
}

// CART
// __________________________________________________
.cart-header {
    display: flex;
    justify-content: space-between;
}
.cart-actions {
    display: flex;
    .cart-total {
        font-weight: 200;
        font-size: 2rem;
        margin-right: 2rem;
    }
}

table.cart {

    @include media('<=phone') {
        thead {
            display: none;
        }
        tbody tr td {
            display: block;
        }
    }
}
.checkout-step {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid $gray;
}


// Payment source form
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.paymentSource-form .stripe-form {
    width: 50%;
    @include media('<=phone') {
        width: 100%;
    }
}
fieldset {
    margin-bottom: 20px;
    border: 0;
    padding: 0;
}

fieldset legend {
    font-weight: bold;
}

.field {
    margin-bottom: 20px;
}
.paymentSource-form .grid {
    display: flex;
    align-items: center;
    margin: 0px -10px;
}

.paymentSource-form .grid .item {
    flex-grow: 1;
    margin: 10px;
}

.paymentSource-form  input {
    width: 100%;
    &[type="radio"], &[type="checkbox"]
    {
        width: auto;
    }
}

.paymentSource-form .multitext .multitextrow {
    display: flex;
    margin: 0px -10px;
}
.paymentSource-form .multitext .multitextrow .text {
    margin: 10px;
}

// CART / ORDER 
// ___________________________________________
table.order-recap, table.cart {
    th, td {
        text-align: left;
        padding: 1rem 0;
        border-bottom: 1px solid $gray;
        &.text-right {
            text-align: right;
        }
        &.sm-text-center {
            @include media('<=phone') {
                text-align: center;
            }
        }
    }
    td {
        font-size: 1.1rem;
        
    }
    tr.item {
        vertical-align: top;
    }
}

// NOTICES
//_________________________________________
.notice {
    padding: .5rem;
    border: 1px solid $gray;
    margin-bottom: 1rem;
    &.error {
        border-color: $poppy;
        color: $poppy;
    }
    p {
        margin-bottom: 0;
    }
}

// FILE UPLOADER
//_________________________________________
.fileuploader {
    background: $white;
}
.fileuploader-input .fileuploader-input-button, .fileuploader-popup .fileuploader-popup-content .fileuploader-popup-button.button-success {
    background: $monstera;
}

.fileuploader-input .fileuploader-input-caption {
    color: $monstera;
    font-weight: 200;
}

.fileuploader-items .fileuploader-item .fileuploader-action.fileuploader-action-remove i {
    background: $flamingo;
    box-shadow: none;
}

.fileuploader.fileuploader-focused:after {
    content: 'Please select a file';
    color: $poppy;
    padding: 10px;
    display: block;
}

///BLOCK SELECTIOn
.block-selection-inventory {
    margin-top: 4rem;
   .label-wrap {
    display: flex;
    justify-content: flex-end;
    .label {
        width: 40.5%;
        text-align: center;
        border: 4px solid #000;
        border-bottom: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem .5rem;
        h4, p {
            margin-bottom: 0;
        }
    }
   }
   .row {
    display: flex;
    border-top: 4px solid #aaa;
        .cell {
            border-right: 4px solid #aaa;
            padding: 1rem 2rem;
            font-size: 1rem;
            &.block-title {
                width: 60%;
            }
            &.arrival-group {
                width: 20%;
                text-align: center;
                span {
                    font-weight: 600;
                }
            }
        }
        &.header {
            border-top: 4px solid #000;
            .cell {
                border-right: 4px solid #aaa;
                padding: 1rem 2rem;
                &.block-heading {
                    width: 60%;
                    font-size: 3rem;
                    line-height: 1.5;
                }
                &.arrival-group-heading {
                    width: 20%;
                    padding: 1rem 1.5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }
            }
        }
        &:last-child {
            border-bottom: 4px solid #000;
        }
   }
}
.block-selection {
    padding-top: 2rem;

    h2 {
        margin-bottom: 0;
    }
    .panel {
        padding: 20px 0;
    }
    .form-column {
        padding: .5rem;
    }
    select, p {
        font-size: 1rem;
    }
}

